@import url('https://fonts.googleapis.com/css2?family=Bungee+Tint&family=Kalam:wght@300;400;700&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: "Kanit", sans-serif;
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  background-color: #7C00FE; /* Light gray background, slightly darker */
}

.navbar {
  height: 30vh;
  width: 100vw;
  background-color: #7C00FE; /* Darker blue */
  color: #ffffff; /* White text for contrast */
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar a {
  text-decoration: none; 
  color: #ffffff; /* White text for links */
}

.navbar a img {
  height: 30vh;
  width: 35vw;
}

.box-container {
  background-color: #fff; /* White background for content areas */
  width: 53vw;
  margin: auto;
  margin-bottom: 5vh;
  padding: 2%;
  box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.2); /* Slightly darker shadow */
  border: 2px solid #999999; /* Darker gray border */
  border-radius: 1rem;
  color: white !important;
}

.category-list {
  display: flex;
  flex: auto;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: auto;
  justify-content: space-around;
  text-align: center;
}

.category-button {
  width: 10vw;
  font-size: 1.2rem;
}

.input-label {
  font-weight: 500;
  margin-bottom: 2rem;
  color: #000; /* Darker blue for labels */
}

label {
  font-size: 1.3rem;
  border: .2px solid #999999; /* Darker gray border */
  border-radius: .5em;
  text-transform: capitalize;
  padding: .5em;
  width: 6rem; /* Default width for larger displays */
  cursor: pointer;
  background-color: #f589cf; /* Slightly darker gray background */
  color: white; /* Darker blue text */
}

.active {
  border: .2px solid #b80078; /* Darker blue border for active state */
  background-color: #ff00a6; /* Darker blue background for active state */
  color: #ffffff; /* White text for active state */
}

.link-input {
  height: 2rem;
  min-width: 50vw; /* Default width for larger displays */
  border-radius: .7em;
  padding: .4em 1rem;
  border: 1px solid #999999; /* Darker gray border */
  font-size: large;
  margin-bottom: 1rem;
}

input:focus {
  outline: 1px solid #003366; /* Darker blue outline for focus state */
  box-shadow: rgba(0, 51, 102, 0.3) .1rem .1rem 1px; /* Slightly darker shadow for focus */
}

.grid-container {
  max-width: min-content;
  margin-inline: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Default: 3 items per row */
  grid-gap: auto;
  gap: 2em 6em;
  justify-content: center;
  align-items: center;
}

.grid-item {
  padding: 10px;
  background-color: #e0e0e0; /* Slightly darker gray background */
  border: 1px solid #999999; /* Darker gray border */
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 5em;
}

.grid-item:hover {
  background-color: #c0c0c0; /* Darker gray on hover */
}

.grid-item.active {
  border: .2px solid #b80078; /* Darker blue border for active state */
  background-color: #ff00a6; /* Darker blue background for active state */
  color: #ffffff; /* White text for active state */
}

.grid-container:last-child {
  margin-top: auto;
}

.price-input {
  text-align: center;
  font-size: x-large;
}

.go-to-payment {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.go-to-payment button {
  padding: 1rem;
  min-width: 20vw;
  border-radius: .7rem;
  cursor: pointer;
  background-color: #0056b3; /* Darker blue for buttons */
  outline: none;
  color: white;
  border: .5px solid #004080; /* Darker blue border */
  transition: background-color 0.3s;
  font-weight: 500;
  font-size: large;
}

.disabled {
  background-color: #67abf4 !important; 
}

.go-to-payment button:hover {
  background-color: #003d80; /* Even darker blue on hover */
  outline: none;
  border: .5px solid #00274d; /* Darker blue border on hover */
}

.paid {
  margin-bottom: 1rem;
}

.success-message {
  color: green;
  text-align: center;
  font-size: 1.5rem;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem;
  pointer-events: none;
  user-select: none;
  margin: auto;
}
/* 
img {
  pointer-events: none;
  user-select: none;
  margin: auto;
} */

.payment-notes {
  text-align: left !important;
  margin: auto 1rem;
  margin-bottom: 2rem;
  color: black;
}

.alipay-code-msg {
  font-size: x-large;
}

.note {
  font-size: x-large;
  color: red;
}

.footer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: 2vh;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .box-container {
    width: 70vw;
  }

  .navbar p {
    font-size: 5vh;
  }

  .grid-container {
    grid-template-columns: repeat(2, 1fr); /* 2 items per row on tablets and smaller displays */
    gap: 1em 4em;
  }

  .link-input {
    width: 65vw; /* Slightly wider on tablets */
  }

  .category-list {
    gap: 1em;
  }

  .category-button {
    width: 80vw !important; /* Slightly wider on tablets */
  }

  .go-to-payment button {
    min-width: 30vw;
  }

  .grid-item {
    width: 25vw !important;
  }

  .payment-notes {
    text-align: left !important;
    margin: auto .5rem;
    margin-bottom: 2rem;
    color: black;
  }
}

@media (max-width: 768px) {
  .box-container {
    width: 80vw;
    margin: 5vh auto;
  }

  .navbar p {
    font-size: 4vh;
  }

  .category-button {
    max-width: 70vw;
  }

  .grid-container {
    grid-template-columns: repeat(2, 1fr); /* 2 items per row on phones */
    gap: 1em 3em;
  }

  .grid-item {
    width: 35vw;
  }

  .link-input {
    min-width: 75vw; /* Wider on phones */
  }


  .category-list {
    flex-direction: column; /* Stack category buttons vertically on phones */
    align-items: center;
  }

  .category-button {
    width: 100%; /* Full width for better touch targets */
    margin-bottom: 1rem; /* Space between buttons */
  }

  .grid-item {
    width: 30vw !important;
  }
}

@media (max-width: 480px) {
  .box-container {
    width: 80vw;
    margin: 3vh auto;
  }

  .navbar {
    height: 27vh;
  }

  .navbar a img {
    height: 27vh;
    width: 60vw;
  }

  .navbar p {
    font-size: 3.5vh;
  }

  .category-button {
    min-width: 70vw;
  }

  .grid-container {
    grid-template-columns: 1fr; /* 1 item per row on very small screens */
    gap: 0.5em 2em;
  }

  .grid-item {
    width: 70vw !important;
  }

  .link-input {
    min-width: 70vw; /* Wider on very small screens */
  }

  .category-button {
    width: 5rem; /* Reduced width on very small screens */
  }

  h2 {
    font-size: 1rem;
  }
}
